<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Dashboard"
})
</script>

<template>
  <div class="row justify-content-center">
    <div class="col-8">
      <b-card no-body>
        <b-card-body>
          <b-accordion class="custom-accordionwithicon accordion-fill-success" id="accordionFill">

            <b-accordion-item title="Quartile 1">
              <div class="table-responsive">
                <table class="table table-nowrap mb-0">
                  <thead>
                  <tr class="text-center bg-soft-light">
                    <th>Date</th>
                    <th>Document</th>
                    <th>Debit</th>
                    <th>Credit</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="text-center">
                    <td>01.05.2023</td>
                    <td>Act 1</td>
                    <td></td>
                    <td>77508.77</td>
                  </tr>
                  <tr class="text-center">
                    <td>01.06.2023</td>
                    <td>Act 2</td>
                    <td>77508.77</td>
                    <td></td>
                  </tr>
                  <tr class="text-center">
                    <td>01.07.2023</td>
                    <td>Act 3</td>
                    <td></td>
                    <td>77508.77</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </b-accordion-item>

            <b-accordion-item title="Quartile 2">
              <div class="table-responsive">
                <table class="table table-nowrap mb-0">
                  <thead>
                  <tr class="text-center bg-soft-light">
                    <th>Date</th>
                    <th>Document</th>
                    <th>Debit</th>
                    <th>Credit</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="text-center">
                    <td>01.05.2023</td>
                    <td>Act 1</td>
                    <td></td>
                    <td>77508.77</td>
                  </tr>
                  <tr class="text-center">
                    <td>01.06.2023</td>
                    <td>Act 2</td>
                    <td>77508.77</td>
                    <td></td>
                  </tr>
                  <tr class="text-center">
                    <td>01.07.2023</td>
                    <td>Act 3</td>
                    <td></td>
                    <td>77508.77</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </b-accordion-item>

            <b-accordion-item title="Quartile 3">
              <div class="table-responsive">
                <table class="table table-nowrap mb-0">
                  <thead>
                  <tr class="text-center bg-soft-light">
                    <th>Date</th>
                    <th>Document</th>
                    <th>Debit</th>
                    <th>Credit</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="text-center">
                    <td>01.05.2023</td>
                    <td>Act 1</td>
                    <td></td>
                    <td>77508.77</td>
                  </tr>
                  <tr class="text-center">
                    <td>01.06.2023</td>
                    <td>Act 2</td>
                    <td>77508.77</td>
                    <td></td>
                  </tr>
                  <tr class="text-center">
                    <td>01.07.2023</td>
                    <td>Act 3</td>
                    <td></td>
                    <td>77508.77</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </b-accordion-item>

            <b-accordion-item>
              <template v-slot:title>
                <div class="d-flex justify-content-between">
                  <span>Quartile 4</span>
                  <span class="badge badge-gradient-info ms-4">ACT 2</span>
                </div>
              </template>
              <div class="table-responsive">
                <table class="table table-nowrap mb-0">
                  <thead>
                  <tr class="text-center bg-soft-light">
                    <th>Date</th>
                    <th>Document</th>
                    <th>Debit</th>
                    <th>Credit</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="text-center">
                    <td>01.05.2023</td>
                    <td>Act 1</td>
                    <td></td>
                    <td>77508.77</td>
                  </tr>
                  <tr class="text-center">
                    <td>01.06.2023</td>
                    <td>Act 2</td>
                    <td>77508.77</td>
                    <td></td>
                  </tr>
                  <tr class="text-center">
                    <td>01.07.2023</td>
                    <td>Act 3</td>
                    <td></td>
                    <td>77508.77</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </b-accordion-item>

          </b-accordion>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<style scoped>

</style>